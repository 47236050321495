/**
 * The Dashboard container
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Page from "../components/Page/Page-component";
import { ScrollToTop as useScrollToTop } from "@bkry/bowline-components/Utils";
import ReactPlayer from "react-player";
import ScrollAnimation from "react-animate-on-scroll";
import { env } from "@bkry/bowline-utils";
import TvBundleSection from "../components/PreorderBundleSection/TvBundleSection";
import ImageContentSplit from "../components/ImageContentSplit";
import { Roadmap } from "@bkry/bowline-components/Roadmap";
// import RaffleWinnerAnnouncement from "../components/RaffleWinnerAnnouncement";
const { REACT_APP_CDN_PATH } = env;

/**
 * Dashboard container, the landing page for logged in users
 */
const Gen3Dashboard = (props, context) => {
  useScrollToTop();
  const [ready, setReady] = useState(true);
  const lang = useSelector((state) => state.i18nState.lang);

  const heroVideo = {
    src: (REACT_APP_CDN_PATH || "") + "/1920x1080_HEADER_VIDEO_HOME_Kl.mp4",
    altText: "Samsung Gen3 Gate",
    type: "video",
    fallback: "",
    key: 1,
  };
  return (
    <Page id="home" title="Samsung Gen3" className="mb-5 overflow-hidden">
      <div className="d-flex justify-content-center">
        {ready ? (
          <Container className="px-0">
            <ReactPlayer
              url={heroVideo.src}
              controls={true}
              playing={true}
              playsinline
              muted={true}
              loop={true}
              width="100%"
              height="auto"
              onReady={() => setReady(true)}
              onError={() => setReady(false)}
              fallback={heroVideo.fallback}
            />
          </Container>
        ) : (
          <img src={heroVideo.fallback} width="100%" height="auto" />
        )}
      </div>
      <Container>
        <TvBundleSection />
      </Container>
      <Container>
        <Row className="bigger-margin-top justify-content-around align-items-center ledger-section">
          <div className="text-center mb-md-0 mb-lg-4">
            <h2 className="text-center h1">
              {context.t("teaser.gen3rator.title")}
            </h2>
          </div>
          <Col xs={{ size: 12 }} md={{ size: 10 }}>
            <p className="text-center">
              {context.t("teaser.gen3rator.shortdescription")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <div
              style={{
                marginLeft: "-60%",
              }}
            >
              <ReactPlayer
                width="150%"
                height="auto"
                url={(REACT_APP_CDN_PATH || "") + "/0004.mp4"}
                loop={true}
                muted={true}
                playing={true}
                playsinline
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload noplaybackrate",
                      disablePictureInPicture: true,
                    },
                  },
                }}
              />
            </div>
          </Col>
          <Col
            xs={{ size: 12, order: 1 }}
            md={{ size: 6, order: 2 }}
            className="d-flex flex-column justify-content-center"
          >
            <ScrollAnimation animateIn="fadeIn" duration={2} delay={1000}>
              <p>
                <span className="h4 mt-md-5 d-block bigger-margin-top">
                  {context.t("teaser.gen3rator.subtitle")}
                </span>
                <br />
                {context.t("teaser.gen3rator.copy")}
              </p>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
      <Container className="bigger-margin-top">
        <Row className="bigger-margin-top justify-content-around align-items-center ledger-section">
          <div className="text-center mb-md-0 mb-lg-4">
            <h2 className="text-center h1">
              {context.t("teaser.tvapp.title")}
            </h2>
          </div>
          <Col xs={{ size: 12 }} md={{ size: 10 }}>
            <p className="text-center">
              <strong>{context.t("teaser.tvapp.copy")}</strong>
            </p>
            <NavLink
              to={`/${lang}/tvapp/`}
              style={{ border: "1px solid", margin: "1rem auto" }}
              className="btn btn-outline-primary w-50 rounded-pill py-2 d-block"
            >
              {context.t("teaser.tvapp.cta")}
            </NavLink>
          </Col>
        </Row>
        <ScrollAnimation animateIn="zoomIn" duration={2} delay={500}>
          <ReactPlayer
            width="100%"
            height="auto"
            url={(REACT_APP_CDN_PATH || "") + "/tvapp.mp4"}
            loop={true}
            muted={true}
            playing={true}
            playsinline
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload noplaybackrate",
                  disablePictureInPicture: true,
                },
              },
            }}
          />
        </ScrollAnimation>
      </Container>

      <Container>
        <ImageContentSplit
          title={context.t("teaser.benefits.title")}
          content={context.t("teaser.benefits.copy")}
          imageUrl={
            (REACT_APP_CDN_PATH || "") +
            "/images/Samsung_Orig_Wordmark_WHITE_RGB.png"
          }
          ctaLink={"/community/benefits/"}
          ctaText={context.t("teaser.benefits.cta")}
        />
      </Container>

      <Container className="bigger-margin-top">
        <Row className="bigger-margin-top justify-content-around align-items-center ledger-section">
          <div className="text-center mb-md-0 mb-lg-4">
            <h2 className="text-center">{context.t("teaser.tip.headline")}</h2>
          </div>
        </Row>
        <ScrollAnimation animateIn="zoomIn" duration={2} delay={500}>
          <img
            src={(REACT_APP_CDN_PATH || "") + "/images/Samsung_Nft_Logic.png"}
            alt="Samsung NFT Tips"
            width="100%"
            height="auto"
          />
        </ScrollAnimation>
      </Container>

      <Container>
        <ImageContentSplit
          title={context.t("teaser.partner.title")}
          content={context.t("teaser.partner.copy")}
          videoUrl={
            (REACT_APP_CDN_PATH || "") +
            "/videos/630x400_Partner_Teaser-Video.mp4"
          }
          ctaLink={"/community/collabs/"}
          ctaText={context.t("teaser.partner.cta")}
          leftAligned
        />
      </Container>
      <Container className="bigger-margin-top bigger-margin-bottom">
        <Roadmap roadmapSlug="gen3" />
      </Container>
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
Gen3Dashboard.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default Gen3Dashboard;
