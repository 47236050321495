import React from "react";
import { Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";

const ImageContentSplit = ({
  imageUrl,
  videoUrl,
  title,
  content,
  leftAligned,
  ctaLink,
  ctaText,
  ctaExternal,
}) => {
  const lang = useSelector((state) => state.i18nState.lang);

  return (
    <Row className="align-items-center bigger-margin-top">
      <Col
        xs={{ size: 12, order: 2 }}
        lg={{ size: 6, order: leftAligned ? 2 : 1 }}
        className="my-5 text-overlap ps-md-5 px-1"
      >
        <h2 className="text-white mb-4 h1 px-2">{title}</h2>
        <div className="d-flex flex-column justify-content-between">
          <p
            className={`fw-bold pb-0 px-2`}
            dangerouslySetInnerHTML={{ __html: content }}
          ></p>
          {ctaLink && (
            <NavLink
              to={`/${lang}${ctaLink}`}
              style={{ border: "1px solid" }}
              className="btn btn-outline-primary w-100 rounded-pill border-1 py-2 btn btn-secondary"
            >
              {ctaText}
            </NavLink>
          )}
          {ctaExternal && (
            <a
              href={ctaExternal}
              target="_blank"
              rel="noreferrer"
              style={{ border: "1px solid" }}
              className="btn btn-outline-primary w-100 rounded-pill border-1 py-2 btn btn-secondary"
            >
              {ctaText}
            </a>
          )}
        </div>
      </Col>
      <Col
        xs={{ size: 12, order: 1 }}
        lg={{ size: 6, order: leftAligned ? 1 : 2 }}
        className="my-5 pt-5 pt-md-0"
      >
        {imageUrl && (
          <ScrollAnimation
            animateIn={leftAligned ? "fadeInLeft" : "fadeInRight"}
            delay={300}
          >
            <img
              src={imageUrl}
              alt={title}
              height={"100%"}
              width="100%"
              style={{ maxWidth: "630px", maxHeight: "400px" }}
              className="align-self-end"
            />
          </ScrollAnimation>
        )}
        {videoUrl && (
          <ReactPlayer
            url={videoUrl}
            controls={false}
            playing={true}
            playsinline
            muted={true}
            loop={true}
            width="100%"
            height="auto"
          />
        )}
      </Col>
    </Row>
  );
};

/**  define proptype for the 'translation' function  */
ImageContentSplit.contextTypes = {
  t: PropTypes.func,
};

ImageContentSplit.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaExternal: PropTypes.string,
  leftAligned: PropTypes.bool,
};

ImageContentSplit.defaultProps = {};

export default ImageContentSplit;
