import React from "react";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { env } from "@bkry/bowline-utils";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import ReactPlayer from "react-player";
const { REACT_APP_CDN_PATH } = env;

const TvBundleSection = (props, context) => {
  const lang = useSelector((state) => state.i18nState.lang);

  // const [activeIndex, setActiveIndex] = useState(0);

  // const next = () => {
  //   const nextIndex = activeIndex === 3 - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // };

  // const previous = () => {
  //   const nextIndex = activeIndex === 0 ? 3 - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // };

  return (
    <div className="align-items-center bigger-margin-top">
      <Row>
        <Col
          xs={{ size: 12, order: 2 }}
          lg={{ size: 6, order: 1 }}
          className="my-5 my-md-2 my-xs-2 text-overlap ps-md-5 px-1"
        >
          <h2 className="text-white mb-4 h1 px-2">
            {context.t("teaser.tvbundle.title")}
          </h2>
          <div className="d-flex flex-column justify-content-between">
            <p className={`fw-bold pb-0 px-2`}>
              {context.t("teaser.tvbundle.copy")}
            </p>
            <small className="text-muted px-2 pb-4 small">
              <NavLink
                to={`/${lang}/disclaimer_tv_bundle`}
                className="text-muted pb-4 small"
              >
                *{context.t("Terms apply")}
              </NavLink>
            </small>
            <Row>
              <Col>
                <a
                  href={`https://www.samsung.com/de/lifestyle-tvs/the-frame/ls03d-50-inch-black-gq50ls03dauxzg/`}
                  style={{ border: "1px solid" }}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary rounded-pill py-2 nav-link"
                >
                  The Frame (2024) &gt;50&apos;&apos;
                </a>
              </Col>
              <Col>
                <a
                  href={`https://www.samsung.com/de/lifestyle-tvs/the-sero/ls05bg-43-inch-the-sero-qled-4k-smart-tv-navy-blue-gq43ls05bguxzg/`}
                  style={{ border: "1px solid" }}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary rounded-pill py-2 nav-link"
                >
                  The Sero (2023)
                </a>
              </Col>
            </Row>
            {/* {lang === "de" ? (
            <a
              href={`https://www.samsung.com/de/lifestyle-tvs/the-frame/ls03d-50-inch-black-gq50ls03dauxzg/`}
              style={{ border: "1px solid" }}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-primary rounded-pill py-2 nav-link"
            >
              {context.t("teaser.tvbundle.cta")}
            </a>
          ) : (
            <a
              href={`https://www.samsung.com/de/lifestyle-tvs/the-frame/ls03d-50-inch-black-gq50ls03dauxzg/`}
              style={{ border: "1px solid" }}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-primary rounded-pill py-2 nav-link"
            >
              {context.t("teaser.tvbundle.cta")}
            </a>
          )} */}
          </div>
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          lg={{ size: 6, order: 2 }}
          className="my-md-0 my-xs-0 pt-md-0 pt-xs-0 my-5 pt-3"
        >
          <ScrollAnimation animateIn="fadeInRight" delay={300}>
            <img
              src={
                (REACT_APP_CDN_PATH || "") + "/images/bundle/bundle_visual.png"
              }
              alt={context.t("teaser.tvbundle.title")}
              width="1260"
              height="709"
              className="align-self-end img-fluid"
            />
          </ScrollAnimation>
          {/* <ScrollAnimation animateIn="fadeInRight" delay={300}>
          <img
            src={
              (REACT_APP_CDN_PATH || "") +
              "/images/bundle/KQ43LSB05AFXKR_008_R-Perspective-L_Navy-Blue.png"
            }
            alt={context.t("teaser.tvbundle.title")}
            height={"100%"}
            width="100%"
            style={{ maxWidth: "630px", maxHeight: "400px" }}
            className="align-self-end"
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" delay={500}>
          <img
            src={(REACT_APP_CDN_PATH || "") + "/nano-x.webp"}
            alt={context.t("teaser.tvbundle.title")}
            height={"100%"}
            width="100%"
            style={{
              maxWidth: "130px",
              maxHeight: "150px",
              marginTop: "-160px",
              marginLeft: "50px",
            }}
            className="align-self-end"
          />
        </ScrollAnimation> */}
        </Col>
      </Row>
      <p className="h2 pt-5 px-1 text-center">
        {lang === "de" ? (
          <>Wie man das Samsung Web3 TV-Bundle kauft</>
        ) : (
          <>How to Purchase the Samsung Web3 TV Bundle</>
        )}
      </p>
      <p className="px-md-5 px-1">
        {lang === "de" ? (
          <>
            Um das Samsung Web3 TV-Bundle zu erhalten, besuchst du
            samsung.com/de und wählst eines der Werbemodelle aus, wie zum
            Beispiel den 2024 The Frame TV (ab 50 Zoll) oder den 2023 The Sero.
            Sobald du eines dieser Modelle in deinen Warenkorb legst, wird das
            Web3-Bundle automatisch in deinem Warenkorb angezeigt und ist bereit
            zur Kasse.
          </>
        ) : (
          <>
            To get the Samsung Web3 TV bundle, visit samsung.com/de and select
            one of the promotional models, such as the 2024 The Frame TV (50
            inches and above) or the 2023 The Sero. Once you add one of these
            models to your cart, the Web3 bundle will automatically appear in
            your cart, ready for checkout.
          </>
        )}
      </p>
      <div className="px-md-5 ps-1 d-flex flex-column align-items-center row">
        <div className="col-md-8 mt-5">
          <ReactPlayer
            url={(REACT_APP_CDN_PATH || "") + "/images/bundle/how_to_order.mp4"}
            controls={false}
            playing={true}
            playsinline
            muted={true}
            loop={true}
            width="100%"
            height="auto"
          />
          <p className="small">
            {lang === "de" ? (
              <>
                <b>Schritt 1:</b>
                <br />
                Wähle die Größe deines The Frame TV oder The Sero Bildschirms
                aus und lege ihn in deinen Warenkorb. Stelle sicher, dass du das
                berechtigte Modell 2024 The Frame TV (ab 50 Zoll) oder das
                Modell 2023 The Sero auswählst.
              </>
            ) : (
              <>
                <b>Step 1:</b>
                <br />
                Choose the size of your The Frame TV or The Sero screen and add
                it to your cart. Ensure you’re selecting the eligible 2024 The
                Frame TV (50 inches and above) or the 2023 The Sero model.
              </>
            )}
            <br />
            <br />
            {lang === "de" ? (
              <>
                <b>Schritt 2:</b>
                <br />
                Nachdem du den Fernseher in deinen Warenkorb gelegt hast, sollte
                das Bundle automatisch erscheinen, wenn dein gewähltes Modell
                berechtigt ist. Überprüfe noch einmal, ob das Bundle enthalten
                ist, bevor du deinen Kauf abschließt.
              </>
            ) : (
              <>
                <b>Step 3:</b>
                <br />
                After adding the TV to your cart, the bundle should
                automatically appear if your chosen model is eligible.
                Double-check that the bundle is included before completing your
                purchase.
              </>
            )}
          </p>
          {/* <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
          >
            {[
              <CarouselItem key={1}>
                <img
                  src={
                    (REACT_APP_CDN_PATH || "") + "/images/bundle/how_to_1.png" // ! TODO Replace
                  }
                  alt={context.t("teaser.tvbundle.title")}
                  width="1260"
                  height="709"
                  className="w-100 img-fluid"
                />
                <p className="small">
                  {lang === "de" ? (
                    <>
                      <b>Schritt 1:</b>
                      <br />
                      Wähle die Größe deines The Frame TV oder The Sero
                      Bildschirms aus und lege ihn in deinen Warenkorb. Stelle
                      sicher, dass du das berechtigte Modell 2024 The Frame TV
                      (ab 50 Zoll) oder das Modell 2023 The Sero auswählst.
                    </>
                  ) : (
                    <>
                      <b>Step 1:</b>
                      <br />
                      Choose the size of your The Frame TV or The Sero screen
                      and add it to your cart. Ensure you’re selecting the
                      eligible 2024 The Frame TV (50 inches and above) or the
                      2023 The Sero model.
                    </>
                  )}
                </p>
              </CarouselItem>,
              <CarouselItem key={2}>
                <img
                  src={
                    (REACT_APP_CDN_PATH || "") + "/images/bundle/how_to_2.png" // ! TODO Replace
                  }
                  alt={context.t("teaser.tvbundle.title")}
                  width="1260"
                  height="709"
                  className="w-100 img-fluid"
                />
                <p className="small">
                  {lang === "de" ? (
                    <>
                      <b>Schritt 2:</b>
                      <br />
                      Lege das Produkt in den Warenkorb, es ist kein Hinweis auf
                      das Bundle in diesem Schritt sichtbar.
                    </>
                  ) : (
                    <>
                      <b>Step 2:</b>
                      <br />
                      Add the product to your cart. There is no extra step for
                      the bundle required.
                    </>
                  )}
                </p>
              </CarouselItem>,
              <CarouselItem key={3}>
                <img
                  src={
                    (REACT_APP_CDN_PATH || "") + "/images/bundle/how_to_3.png" // ! TODO Replace
                  }
                  alt={context.t("teaser.tvbundle.title")}
                  width="1260"
                  height="709"
                  className="w-100 img-fluid"
                />
                <p className="small">
                  {lang === "de" ? (
                    <>
                      <b>Schritt 3:</b>
                      <br />
                      Nachdem du den Fernseher in deinen Warenkorb gelegt hast,
                      sollte das Bundle automatisch erscheinen, wenn dein
                      gewähltes Modell berechtigt ist. Überprüfe noch einmal, ob
                      das Bundle enthalten ist, bevor du deinen Kauf abschließt.
                    </>
                  ) : (
                    <>
                      <b>Step 3:</b>
                      <br />
                      After adding the TV to your cart, the bundle should
                      automatically appear if your chosen model is eligible.
                      Double-check that the bundle is included before completing
                      your purchase.
                    </>
                  )}
                </p>
              </CarouselItem>,
            ]}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel> */}
        </div>
      </div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
TvBundleSection.contextTypes = {
  t: PropTypes.func,
};

TvBundleSection.propTypes = {};

TvBundleSection.defaultProps = {};

export default TvBundleSection;
